<template lang="pug">
InputGroupControl(
  :label="$t('padding')"
  type="padding"
  :customProperty="customProperty"
  :editMobile="deviceSelector"
  :allowNegative="false"
)
</template>
<script>
  import InputGroupControl from './InputGroup.vue';

  export default {
    components: { InputGroupControl },
    props: {
      customProperty: {
        type: String,
        default: null,
      },
      deviceSelector: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>
