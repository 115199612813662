<template lang="pug">
ProductBlockWrapper
  FontFamily(v-model="fontFamily" layout="row" manage)
  FontStyle(
    :label="$t('style')"
    :textWeight.sync="textWeight"
    :fontItalic.sync="fontItalic"
    :textDecoration.sync="textDecoration"
    :textStrikeThrough.sync="textStrikeThrough"
    showTextStrikeThrough
  )
  RangeInput(:label="$t('fontSize')" v-model="fontSize" :min="8" :max="90" :step="1" editMobile)
  OmColorInput(
    :label="$t('color')"
    :property="`${baseProperty}.color`"
    typeOfComponent="product"
    layout="col"
    dsAllowed
    editMobile
  )
  Margin(:customProperty="`subElements.${subItem}.$device.margin`")
  Padding(:customProperty="`subElements.${subItem}.$device.padding`")
</template>
<script>
  import itemMixin from '@/editor/mixins/item';
  import ProductBlockWrapper from './Wrapper.vue';
  import FontFamily from '../../Controls/FontFamily.vue';
  import FontStyle from '../../FontStyle/FontStyle.vue';
  import RangeInput from '../../RangeInput/RangeInput.vue';
  import Margin from '../../Controls/InputGroup/Margin.vue';
  import Padding from '../../Controls/InputGroup/Padding.vue';

  export default {
    components: {
      ProductBlockWrapper,
      FontFamily,
      FontStyle,
      RangeInput,
      Margin,
      Padding,
    },
    mixins: [itemMixin],
    props: {
      subItem: {
        type: String,
      },
    },
    data: () => ({
      canEditMobile: true,
    }),
    computed: {
      baseProperty() {
        return `subElements.${this.subItem}Text.$device`;
      },
      fontFamily: {
        get() {
          return this.getValueOf(`${this.baseProperty}.fontFamily`);
        },
        set(v) {
          this.setValueOf(`${this.baseProperty}.fontFamily`, v);
        },
      },
      textWeight: {
        get() {
          return this.getValueOf(`${this.baseProperty}.textWeight`);
        },
        set(v) {
          this.setValueOf(`${this.baseProperty}.textWeight`, v);
        },
      },
      fontItalic: {
        get() {
          return this.getValueOf(`${this.baseProperty}.fontItalic`);
        },
        set(v) {
          this.setValueOf(`${this.baseProperty}.fontItalic`, v);
        },
      },
      textDecoration: {
        get() {
          return this.getValueOf(`${this.baseProperty}.textDecoration`);
        },
        set(v) {
          this.setValueOf(`${this.baseProperty}.textDecoration`, v);
        },
      },
      textStrikeThrough: {
        get() {
          return this.getValueOf(`${this.baseProperty}.textStrikeThrough`);
        },
        set(v) {
          this.setValueOf(`${this.baseProperty}.textStrikeThrough`, v);
        },
      },
      fontSize: {
        get() {
          return Number(this.getValueOf(`${this.baseProperty}.fontSize`));
        },
        set(v) {
          this.setValueOf(`${this.baseProperty}.fontSize`, v);
        },
      },
    },
  };
</script>
