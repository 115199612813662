<template lang="pug">
.ds-alignment.design-system
  PaneLayout(:layout="layout" gap="4px")
    template(#left)
      .d-flex.align-items-center
        .ds-alignment-label {{ label }}
        DeviceSelector(v-if="editMobile" :hasViewText="false")
    template(#right)
      .ds-alignment-content.d-flex.w-100
        .ds-alignment-content-small-block.cursor-pointer(
          v-for="option in options"
          @click="$emit('input', option.value)"
          :class="{ active: value === option.value }"
        )
          .ds-alignment-content-small-block-icon
            component(:is="getIconFrom(option)" size="1.5rem")
</template>

<script>
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';
  import {
    UilHorizontalAlignLeft,
    UilHorizontalAlignCenter,
    UilHorizontalAlignRight,
    UilVerticalAlignBottom,
    UilVerticalAlignCenter,
    UilVerticalAlignTop,
    UilAlignLeft,
    UilAlignCenter,
    UilAlignRight,
  } from '@iconscout/vue-unicons';
  import PaneLayout from '../PaneLayout.vue';

  const ucFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  export default {
    name: 'Alignment',
    components: {
      PaneLayout,
      DeviceSelector,
      UilHorizontalAlignLeft,
      UilHorizontalAlignCenter,
      UilHorizontalAlignRight,
      UilVerticalAlignBottom,
      UilVerticalAlignCenter,
      UilVerticalAlignTop,
      UilAlignLeft,
      UilAlignCenter,
      UilAlignRight,
    },
    props: {
      label: {
        type: String,
      },
      value: {
        type: String,
      },
      vertical: {
        type: Boolean,
        default: false,
      },
      textAlign: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Array,
        required: true,
      },
      editMobile: {
        type: Boolean,
        default: false,
      },
      layout: {
        type: String,
        default: 'col',
        options: ['row', 'col'],
        validator: (value) => {
          return ['row', 'col'].includes(value);
        },
      },
    },
    methods: {
      getIconFrom(option) {
        const orientation = this.vertical ? 'Vertical' : 'Horizontal';
        const direction = ucFirst(option.text);

        return this.textAlign ? `UilAlign${direction}` : `Uil${orientation}Align${direction}`;
      },
    },
  };
</script>
<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .ds-alignment
    &-label
      color: $om-dark-grey-3
      font-size: 0.75rem
      line-height: 16px
    &-content
      gap: 4px
      color: $om-dark-grey-3
      justify-content: flex-end
      &-small-block
        height: 2rem
        width: 2rem
        display: flex
        align-items: center
        justify-content: center
        border-radius: 4px
        transition: background-color .15s ease-in
        &:hover
          background-color: $om-gray-200
        &-icon
          display: flex
      .active
        color: $om-orange
        background-color: rgba(237, 90, 41, 0.10)
</style>
