<template lang="pug">
ProductBlockWrapper
  RangeInput(:label="$t('size')" v-model="height" :min="20" :max="800" :step="1")
  Margin(customProperty="subElements.image.$device.margin")
  Padding(customProperty="subElements.image.$device.padding")
  Border(
    selectedProperty="subElements.image.$device.border.selectedBorder"
    typeProperty="subElements.image.$device.border.type"
    widthProperty="subElements.image.$device.border.width"
    coloringProperty="subElements.image.$device.border.color"
    typeOfComponent="product"
  )
  CornerRadius(
    radiusProperty="subElements.image.$device.border.radius"
    chainedProperty="subElements.image.$device.border.radiusChained"
    customThemeRoundingProperty="subElements.image.$device.border.customThemeRounding"
  )
  Shadow(
    v-model="imageShadow"
    typeOfComponent="product"
    coloringProperty="subElements.image.$device.shadow.color"
  )
</template>
<script>
  import itemMixin from '@/editor/mixins/item';
  import ProductBlockWrapper from './Wrapper.vue';
  import RangeInput from '../../RangeInput/RangeInput.vue';
  import Margin from '../../Controls/InputGroup/Margin.vue';
  import Padding from '../../Controls/InputGroup/Padding.vue';
  import Border from '../../Controls/Border.vue';
  import CornerRadius from '../../Controls/CornerRadius.vue';
  import Shadow from '../../Controls/Shadow.vue';

  export default {
    components: {
      ProductBlockWrapper,
      RangeInput,
      Margin,
      Padding,
      Border,
      CornerRadius,
      Shadow,
    },
    mixins: [itemMixin],
    data: () => ({
      canEditMobile: true,
    }),
    computed: {
      height: {
        get() {
          return Number(this.getValueOf('subElements.image.$device.height'));
        },
        set(v) {
          this.setValueOf('subElements.image.$device.height', v);
        },
      },
      imageShadow: {
        get() {
          return this.getValueOf('subElements.image.$device.shadow.type');
        },
        set(v) {
          this.setValueOf('subElements.image.$device.shadow.type', v);
        },
      },
    },
  };
</script>
