<template lang="pug">
.ds-font-style.design-system
  PaneLayout(layout="col" gap="4px")
    template(#left)
      .d-flex.align-items-center
        .ds-font-style-label {{ label }}
        DeviceSelector(v-if="editMobile" :hasViewText="false")
    template(#right)
      .ds-font-style-content.d-flex.w-100
        .ds-font-style-content-small-block.cursor-pointer(
          v-if="!hideTextWeight"
          @click="$emit('update:textWeight', !textWeight)"
          :class="{ active: textWeight }"
        )
          UilBold(size="2rem")
        .ds-font-style-content-small-block.cursor-pointer(
          @click="$emit('update:fontItalic', !fontItalic)"
          :class="{ active: fontItalic }"
        )
          UilItalic(size="2rem")
        .ds-font-style-content-small-block.cursor-pointer(
          @click="$emit('update:textDecoration', !textDecoration)"
          :class="{ active: textDecoration }"
        )
          UilUnderline(size="2rem")
        .ds-font-style-content-small-block.cursor-pointer(
          v-if="showTextStrikeThrough"
          @click="$emit('update:textStrikeThrough', !textStrikeThrough)"
          :class="{ active: textStrikeThrough }"
        )
          UilTextStrikeThrough(size="2rem")
</template>
<script>
  import { UilBold, UilItalic, UilUnderline, UilTextStrikeThrough } from '@iconscout/vue-unicons';
  import DeviceSelector from '@/editor/components/sidebar/components/DeviceSelector.vue';
  import PaneLayout from '../PaneLayout.vue';

  export default {
    components: {
      UilBold,
      UilItalic,
      UilUnderline,
      UilTextStrikeThrough,
      DeviceSelector,
      PaneLayout,
    },
    props: {
      label: {
        type: String,
      },
      editMobile: {
        type: Boolean,
        default: false,
      },
      textWeight: {
        type: Boolean,
        required: false,
      },
      fontItalic: {
        type: Boolean,
        required: true,
      },
      textDecoration: {
        type: Boolean,
        required: true,
      },
      textStrikeThrough: {
        type: Boolean,
        required: false,
      },
      hideTextWeight: {
        type: Boolean,
        default: false,
      },
      showTextStrikeThrough: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .ds-font-style
    &-label
      font-size: 0.75rem
      font-weight: 400
      color: $om-dark-grey-3
    &-content
      justify-content: flex-end
      &-small-block
        height: 2rem
        width: 2rem
        color: $om-dark-grey-3
        border-radius: 4px
        transition: background-color .15s ease-in
        &:hover
          background-color: $om-gray-200
        &.active
          color: $om-orange
        .btn
          padding: unset
</style>
