<template lang="pug">
Alignment(
  :label="labelOverride || $t('horizontalAlignment')"
  v-model="align"
  :options="alignOptions"
  :layout="layout"
  :editMobile="editMobile"
  :textAlign="textAlign"
  :vertical="vertical"
)
</template>
<script>
  import Alignment from '../../Alignment/Alignment.vue';

  export default {
    components: {
      Alignment,
    },
    props: {
      labelOverride: {
        type: String,
        default: null,
      },
      value: {
        type: String,
      },
      editMobile: {
        type: Boolean,
        default: false,
      },
      vertical: {
        type: Boolean,
        default: false,
      },
      textAlign: {
        type: Boolean,
        default: false,
      },
      flexOptions: {
        type: Boolean,
        default: false,
      },
      justifyOptions: {
        type: Boolean,
        default: false,
      },
      layout: {
        type: String,
        default: 'col',
        options: ['row', 'col'],
        validator: (value) => {
          return ['row', 'col'].includes(value);
        },
      },
    },
    computed: {
      align: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit('input', v);
        },
      },
      alignOptions() {
        const firstOpt = this.vertical ? 'top' : 'left';
        const lastOpt = this.vertical ? 'bottom' : 'right';

        if (this.justifyOptions) {
          return [
            { text: 'left', value: 'om-justify-left' },
            { text: 'center', value: 'om-justify-center' },
            { text: 'right', value: 'om-justify-right' },
          ];
        }

        if (this.flexOptions === false) {
          return [
            { text: firstOpt, value: firstOpt },
            { text: 'center', value: 'center' },
            { text: lastOpt, value: lastOpt },
          ];
        }

        return [
          { text: firstOpt, value: 'flex-start' },
          { text: 'center', value: 'center' },
          { text: lastOpt, value: 'flex-end' },
        ];
      },
    },
  };
</script>
